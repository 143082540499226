<template>
  <default-layout :homepage="false" :breadcrumbs="[]">
    <h1 style="display: none;">{{ $t('general.homepage') }}</h1>
    <search />
    <listings
      :listingType="'R'"
      key="listings-commercials"
      :category="'commercial'"
      :title="$t('homepage.title.CRent')"
      :seeAllUrl="'/rent/search/all?property_type_category_group_id=1'"
    />
    <listings
      :listingType="'S'"
      :useBackground="true"
      key="listings-commercials-sell"
      :category="'commercial'"
      :title="$t('homepage.title.CSell')"
      :seeAllUrl="'/buy/search/all?property_type_category_group_id=1'"
    />

    <!--    <listings-->
    <!--      key="listings-project-partners"-->
    <!--      :category="'projectpartner'"-->
    <!--      :title="$t('homepage.title.PSell')"-->
    <!--      :seeAllUrl="'/beli/search/semua?search_type=1'"-->
    <!--    />-->

    <listings
      :listingType="'R'"
      key="listings-residential"
      :category="'residential'"
      :title="$t('homepage.title.RRent')"
      :seeAllUrl="'/rent/search/all?property_type_category_group_id=2'"
    />
    <listings
      :listingType="'S'"
      :useBackground="true"
      key="listings-residential-sell"
      :category="'residential'"
      :title="$t('homepage.title.RSell')"
      :seeAllUrl="'/buy/search/all?property_type_category_group_id=2'"
    />
    <about></about>
    <script v-html="jsonldBreadcrumb" type="application/ld+json"></script>
  </default-layout>
</template>

<script>
import DefaultLayout from '@/layouts/default';
import Search from '@/components/home/search';
import Listings from '@/components/home/listings';
import About from '@/components/home/about';
import { mapState } from 'vuex';

import DefaultHeadMixin from '@/mixins/defaultHead';

export default {
  name: 'index',
  mixins: [DefaultHeadMixin],
  components: {
    DefaultLayout,
    Search,
    Listings,
    About,
  },
  async fetch({ store, route }) {
    let listingType = 'R';
    if (route.name === 'home-rent') {
      listingType = 'R';
    } else if (route.name === 'home-buy') {
      listingType = 'S';
    }

    store.dispatch('v2/search/setListingType', {
      payload: listingType,
      query: route.query,
      clearRoute: true,
    });

    if (route.name === 'home') {
      store.commit('head/RESTORE_DEFAULT_VALUE', route);
    } else {
      const baseUrl = store.state.global.baseUrl;
      const title =
        (listingType === 'S' ? 'Buy' : 'Rent') +
        ' Property in Propfix.sg - Online Platform for Renting and Buying Property.';
      const description =
        'Enjoy the convenience of renting, buying, and selling property in Propfix platform with secure and flexible online transacttion. Propfix helps you rent, sell, and buy property from HDB, condo, landed house, retail, hdb shophouse, food&beverage space, medical space, office, co-working space, serviced office, general industrial, factory, workshop, warehouse, shophouse, and land lot.';
      const head = {
        title: title,
        meta: [
          {
            hid: 'description',
            name: 'description',
            content: description,
          },
          {
            hid: 'og-description',
            name: 'og:description',
            content: description,
          },
        ],
        link: [
          {
            rel: 'canonical',
            href: baseUrl + route.fullPath,
            id: 'canonical',
          },
        ],
      };
      store.commit('head/SET_CONTENT', head);
    }
  },

  computed: {
    ...mapState({
      listingType: state => state.v2.search.listingType,
    }),
    jsonldBreadcrumb() {
      let baseUrl = this.$store.state.global.baseUrl;
      return {
        '@context': 'https://schema.org',
        '@type': 'BreadcrumbList',
        itemListElement: [
          {
            '@type': 'ListItem',
            position: 1,
            name: 'Homepage',
            item: baseUrl + '/',
          },
        ],
      };
    },
  },
  methods: {},
};
</script>
