<template>
  <div>
    <section class="home--map-container">
      <div class="container">
        <h2 class="title--text mb-4-5">
          {{ $t('home.map.title') }}
        </h2>
        <div class="content mb-4-5">
          <img
            class="map--image"
            src="https://rentfixs3-sg.s3.ap-southeast-1.amazonaws.com/public/static/img/map/v2/sg_map.png"
            alt="Propfix Map"
            @click="goToSearch"
          />
        </div>
      </div>
    </section>
    <section class="home--about-container">
      <div class="container">
        <h2 class="title--text mb-4-5">
          {{ $t('home.about.title') }}
        </h2>
        <div class="content mb-4-5">
          <div class="content--text" v-html="$t('home.about.message1')"></div>
        </div>
        <!--      <div class="see&#45;&#45;all">-->
        <!--        <router-link to="/about-us" class="btn">-->
        <!--          {{ $t('home.seeMore') }}-->
        <!--        </router-link>-->
        <!--      </div>-->
      </div>
    </section>
  </div>
</template>

<script>
export default {
  computed: {},
  methods: {
    goToSearch() {
      this.$store.dispatch('v2/search/goToSearchPage', {
        router: this.$router,
        params: this.$route.params,
      });
    },
  },
};
</script>
