<template>
  <div
    v-observe-visibility="{
      callback: visibilityChanged,
      once: true,
    }"
  >
    <section
      class="home--listings-container"
      :class="useBackground ? 'home--listing-background' : ''"
    >
      <div class="container">
        <div class="d-flex justify-content-between align-items-center mb-4-5">
          <h2 class="title--text">
            {{ title }}
          </h2>
          <div class="see--all">
            <router-link class="btn btn-primary btn--all mr-3 my-auto col-auto" :to="seeAllUrl">{{
              $t('home.seeAll')
            }}</router-link>
          </div>
        </div>
        <div class="slider">
          <slick-listings
            :category="category"
            :premier="false"
            :contents="listListing"
            v-if="listListing && listListing.length > 0"
          />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import SlickListings from './partials/slick-listings';
export default {
  props: {
    useBackground: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      required: true,
    },
    seeAllUrl: {
      type: String,
      required: true,
    },
    listingType: {
      type: String,
      default: 'R',
    },
    category: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      listListing: [],
    };
  },
  components: { SlickListings },
  computed: {},
  methods: {
    visibilityChanged(value) {
      if (value) {
        this.getListings();
      }
    },
    async getListings() {
      let query = {};
      query.listing_type = this.listingType;

      query.paginate = 6;

      let homePaginate = 8;
      if (this.$device && this.$device.isMobile) homePaginate = 5;
      let url = '/api/v2/search';
      switch (this.category) {
        case 'premier': {
          break;
        }
        case 'residential': {
          query.paginate = homePaginate;
          query.property_type_category_group_id = 2;
          break;
        }
        case 'commercial': {
          query.paginate = homePaginate;
          query.property_type_category_group_id = 1;
          break;
        }
        case '360': {
          query.paginate = homePaginate;
          query.pano_required = true;
          break;
        }
        case 'projectpartner': {
          query.paginate = homePaginate;
          url = '/api/v2/search/project-partners';
          break;
        }
        case 'discount': {
          query.paginate = homePaginate;
          query.discount = true;
          break;
        }
      }

      const { data } = await this.$http.get(url, {
        params: query,
      });
      this.listListing = data.data.data;
      console.log('result: ', data.data.data);
    },
  },
};
</script>
