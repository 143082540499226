<template>
  <section class="home--search-container">
    <div class="home--search-container-bg"></div>
    <div class="container">
      <div class="home--banner">
        <!--        <transition>-->
        <!--          <router-link-->
        <!--            v-if="selectedBanner && selectedBanner.primary_photo && selectedBanner.photos"-->
        <!--            :key="`banner-${selectedBanner.id}`"-->
        <!--            :to="selectedBanner.links ? selectedBanner.links.detail : ''"-->
        <!--            class="home&#45;&#45;banner-content"-->
        <!--          >-->
        <!--            <div class="home&#45;&#45;banner-img-container">-->
        <!--              <picture>-->
        <!--                <source :srcset="selectedBannerPhoto" type="image/webp" />-->
        <!--                <source :srcset="getFallbackImage(selectedBannerPhoto)" type="image/jpeg" />-->
        <!--                <img-->
        <!--                  :src="getFallbackImage(selectedBannerPhoto)"-->
        <!--                  class="home&#45;&#45;banner-img home&#45;&#45;banner-left"-->
        <!--                  :alt="`${selectedBanner.listing_title} - Propfix`"-->
        <!--                />-->
        <!--              </picture>-->
        <!--              <picture>-->
        <!--                <source :srcset="randomBannerPhoto" type="image/webp" />-->
        <!--                <source :srcset="getFallbackImage(randomBannerPhoto)" type="image/jpeg" />-->
        <!--                <img-->
        <!--                  :src="getFallbackImage(randomBannerPhoto)"-->
        <!--                  class="home&#45;&#45;banner-img home&#45;&#45;banner-right"-->
        <!--                  :alt="`${selectedBanner.listing_title} - Propfix`"-->
        <!--                />-->
        <!--              </picture>-->
        <!--            </div>-->
        <!--          </router-link>-->
        <!--        </transition>-->
        <section class="home--banner-container">
          <div class="slider">
            <client-only>
              <slick-banner :banners="banners" v-if="banners && banners.length > 0"></slick-banner>
            </client-only>
          </div>
        </section>
      </div>
      <div class="search--bottom-part">
        <div class="search--container">
          <rent-sell-switch
            class="justify-content-center"
            :listingType="listingType"
            :isHomepage="true"
            @changeType="changeType"
          ></rent-sell-switch>
          <form @submit.prevent="goToSearch">
            <div class="search--listing-wrapper">
              <client-only>
                <vue-bootstrap-typeahead
                  ref="searchTypehead"
                  :data="suggestions"
                  v-model="currentKeyword"
                  inputClass="basic--input"
                  class="input--wrapper"
                  :serializer="s => s.name"
                  :placeholder="$t('home.searchBarPlaceholder')"
                  @hit="selectedSuggestion"
                >
                  <template slot="suggestion" slot-scope="{ data, htmlText }">
                    <div class="d-flex align-items-center">
                      <i
                        class="ion-earth"
                        v-if="data.category === 'city' || data.category === 'province'"
                      ></i>
                      <i
                        class="icon icon--building icon--sm"
                        v-if="data.category === 'listing'"
                      ></i>
                      <span class="ml-4" v-html="htmlText"></span>
                    </div>
                  </template>
                </vue-bootstrap-typeahead>
              </client-only>
              <button class="input--btn" type="submit">
                <img src="@/assets/img/icons/search.svg" alt="Search" />
              </button>
            </div>
          </form>
          <!--          <div class="property&#45;&#45;types-wrapper">-->
          <!--            <router-link-->
          <!--              :to="`/${listingType === 'R' ? 'rent' : 'buy'}/search${content.url}`"-->
          <!--              class="property&#45;&#45;type-item"-->
          <!--              v-for="(content, index) in propertyTypes"-->
          <!--              :key="`banner-property-type-${index}`"-->
          <!--            >-->
          <!--              <div class="img&#45;&#45;wrapper">-->
          <!--                <img :src="content.img" :alt="content.name" />-->
          <!--              </div>-->
          <!--              <div>{{ content.name }}</div>-->
          <!--            </router-link>-->
          <!--          </div>-->
          <!--          <div class="switch&#45;&#45;type-group" @click="changePropertyTypeGroup">-->
          <!--            <span v-if="propertyTypeGroup === 'COMMERCIAL'"-->
          <!--              >Switch to {{ $t('general.RESIDENTIAL') }} ></span-->
          <!--            >-->
          <!--            <span v-else-if="propertyTypeGroup === 'RESIDENTIAL'"-->
          <!--              >Switch to {{ $t('general.COMMERCIAL') }} ></span-->
          <!--            >-->
          <!--          </div>-->
          <div class="type--dropdown-group">
            <home-listing-type
              :listingType="listingType"
              :propertyTypes="newResidentialTypes"
              :title="$t('general.RESIDENTIAL')"
            ></home-listing-type>
            <home-listing-type
              :listingType="listingType"
              :propertyTypes="newCommercialTypes"
              :title="$t('general.COMMERCIAL')"
            ></home-listing-type>
          </div>
        </div>
        <!--        <transition>-->
        <!--          <div-->
        <!--            class="home&#45;&#45;banner-desc"-->
        <!--            v-if="selectedBanner"-->
        <!--            :key="`banner-desc-${selectedBanner.id}`"-->
        <!--          >-->
        <!--            <div class="top&#45;&#45;text mb-2 multiline-ellipsis-4">-->
        <!--              {{ selectedBanner.listing_title }}-->
        <!--            </div>-->
        <!--            &lt;!&ndash;              <div class="top&#45;&#45;text mb-2">&ndash;&gt;-->
        <!--            &lt;!&ndash;                {{ selectedBanner.user.name }}&ndash;&gt;-->
        <!--            &lt;!&ndash;              </div>&ndash;&gt;-->
        <!--            <div class="bottom&#45;&#45;text btn-primary" v-if="selectedBanner.user">-->
        <!--              {{ selectedBanner.user.phone }}-->
        <!--            </div>-->
        <!--          </div>-->
        <!--        </transition>-->
      </div>
    </div>
  </section>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import LodashMixin from '@/mixins/lodash';
import HelperMixin from '@/mixins/helpers';
const RentSellSwitch = () => import('@/components/utils/rent-sell-switch');
const HomeListingType = () => import('@/components/home/partials/home-listing-type');
const SlickBanner = () => import('@/components/home/partials/slick-banner');

const VueBootstrapTypeahead = () =>
  import('@/components/utils/vue-bootstrap-typeahead/VueBootstrapTypeahead');
export default {
  components: {
    VueBootstrapTypeahead,
    RentSellSwitch,
    HomeListingType,
    SlickBanner,
  },
  mixins: [LodashMixin, HelperMixin],
  data: () => ({
    timer: 3,
    selectedBannerIndex: 0,
    suggestions: [],
    getSuggestionLoading: false,
    propertyTypeGroup: 'RESIDENTIAL',
    propertyTypes: [],
    residentialTypes: [
      {
        id: 31,
        img:
          'https://rentfixs3-sg.s3-ap-southeast-1.amazonaws.com/public/static/img/property-type/hdb.svg',
        name: 'HDB',
        url: '/hdb',
        onlyRent: false,
      },
      {
        id: 32,
        img:
          'https://rentfixs3-sg.s3-ap-southeast-1.amazonaws.com/public/static/img/property-type/condo.svg',
        name: 'Condo',
        url: '/condo',
        onlyRent: false,
      },
      {
        id: 33,
        img:
          'https://rentfixs3-sg.s3-ap-southeast-1.amazonaws.com/public/static/img/property-type/home.svg',
        name: 'Landed House',
        url: '/landed%20house',
        onlyRent: false,
      },
    ],
    commercialTypes: [
      {
        id: 19,
        img:
          'https://rentfixs3-sg.s3-ap-southeast-1.amazonaws.com/public/static/img/property-type/office-building.svg',
        name: 'Office',
        url: '/office',
        onlyRent: false,
      },
      {
        id: 15,
        img:
          'https://rentfixs3-sg.s3-ap-southeast-1.amazonaws.com/public/static/img/property-type/shop.svg',
        name: 'Retail',
        url: '/retail',
        onlyRent: false,
      },
      {
        id: 34,
        img:
          'https://rentfixs3-sg.s3-ap-southeast-1.amazonaws.com/public/static/img/property-type/shophouse.svg',
        name: 'Shophouse',
        url: '/shophouse',
        onlyRent: false,
      },
      {
        id: 16,
        img:
          'https://rentfixs3-sg.s3-ap-southeast-1.amazonaws.com/public/static/img/property-type/hdb-shophouse.svg',
        name: 'HDB Shophouse',
        url: '/hdb%20shophouse',
        onlyRent: false,
      },
      // {
      //   id: 17,
      //   img:
      //     'https://rentfixs3-sg.s3-ap-southeast-1.amazonaws.com/public/static/img/property-type/condo.svg',
      //   name: 'Food & Beverage',
      //   url: '/food%20&%20beverage',
      //   onlyRent: false,
      // },
      {
        id: 18,
        img:
          'https://rentfixs3-sg.s3-ap-southeast-1.amazonaws.com/public/static/img/property-type/hospital.svg',
        name: 'Medical',
        url: '/medical',
        onlyRent: false,
      },
      // {
      //   id: 20,
      //   img:
      //     'https://rentfixs3-sg.s3-ap-southeast-1.amazonaws.com/public/static/img/property-type/condo.svg',
      //   name: 'Co-working Space',
      //   url: '/co-working%20space',
      //   onlyRent: true,
      // },
      // {
      //   id: 21,
      //   img:
      //     'https://rentfixs3-sg.s3-ap-southeast-1.amazonaws.com/public/static/img/property-type/condo.svg',
      //   name: 'Serviced Office',
      //   url: '/serviced%20office',
      //   onlyRent: true,
      // },
      // {
      //   id: 22,
      //   img:
      //     'https://rentfixs3-sg.s3-ap-southeast-1.amazonaws.com/public/static/img/property-type/condo.svg',
      //   name: 'General Industrial',
      //   url: '/general%20industrial',
      //   onlyRent: false,
      // },
      {
        id: 24,
        img:
          'https://rentfixs3-sg.s3-ap-southeast-1.amazonaws.com/public/static/img/property-type/warehouse.svg',
        name: 'Warehouse',
        url: '/warehouse',
        onlyRent: false,
      },
      {
        id: 35,
        img:
          'https://rentfixs3-sg.s3-ap-southeast-1.amazonaws.com/public/static/img/property-type/land.svg',
        name: 'Land',
        url: '/land',
        onlyRent: false,
      },
      {
        id: 23,
        img:
          'https://rentfixs3-sg.s3-ap-southeast-1.amazonaws.com/public/static/img/property-type/factory.svg',
        name: 'Factory',
        url: '/factory',
        onlyRent: false,
      },
      // {
      //   id: 25,
      //   img:
      //     'https://rentfixs3-sg.s3-ap-southeast-1.amazonaws.com/public/static/img/property-type/condo.svg',
      //   name: 'Workshop',
      //   url: '/workshop',
      //   onlyRent: true,
      // },
    ],
    meta: null,
    listings: [],
    loadingMap: true,
    randomIndex: 0,
  }),
  computed: {
    ...mapState({
      listingType: state => state.v2.search.listingType,
      searchType: state => state.v2.search.searchType,
      searchTerm: state => state.v2.search.searchTerm,
      banners: state => state.v2.banner.banners,
    }),
    ...mapGetters({
      activeRangePrice: 'v2/search/activeRangePrice',
      rangePrices: 'v2/search/rangePricesSearch',
    }),
    currentKeyword: {
      get() {
        return this.$store.state.v2.search.currentKeyword;
      },
      set(value) {
        this.$store.commit('v2/search/SET_CURRENT_KEYWORD', value);
        this.$store.commit('v2/search/SET_SEARCH_TERM', value);
      },
    },
    selectedBanner() {
      // return this.banners[0]; // For stopping the banner for debugging
      if (this.banners && this.banners.length > 0) {
        return this.banners[this.selectedBannerIndex];
      } else {
        return null;
      }
    },
    selectedBannerPhoto() {
      let photo = this.selectedBanner.primary_photo.url;
      photo = photo.replace('/300', '/1080');
      return photo;
    },
    randomBannerPhoto() {
      let photo =
        this.selectedBanner.photos.length > 1
          ? this.selectedBanner.photos[this.randomIndex].url
          : this.selectedBanner.primary_photo.url;
      photo = photo.replace('/300', '/1080');
      return photo;
    },
    newResidentialTypes() {
      if (this.listingType === 'S') {
        return this.$store.state.v2.masters.residentialTypesSell;
      } else {
        return this.$store.state.v2.masters.residentialTypesRent;
      }
    },
    newCommercialTypes() {
      if (this.listingType === 'S') {
        return this.$store.state.v2.masters.commercialTypesSell;
      } else {
        return this.$store.state.v2.masters.commercialTypesRent;
      }
    },
  },
  watch: {
    currentKeyword() {
      this.debouncedQuery();
    },
    listingType() {
      this.loadPropertyTypeList();
    },
    timer: {
      handler(value) {
        if (value > 0) {
          setTimeout(() => {
            this.timer--;
          }, 1000);
        } else {
          this.timer = 6;
          if (this.banners && this.banners.length > 0) {
            if (this.selectedBannerIndex < this.banners.length - 1) {
              this.selectedBannerIndex++;
            } else {
              this.selectedBannerIndex = 0;
            }
            if (this.banners[this.selectedBannerIndex]) {
              this.randomIndex = this.getRandomInt(
                0,
                this.banners[this.selectedBannerIndex].photos.length - 1,
              );
            }
          }
        }
      },
      immediate: true, // This ensures the watcher is triggered upon creation
    },
  },
  mounted() {
    this.debouncedQuery = this.debounce(this.getSuggestions, 500);
    this.currentKeyword = this.searchTerm;
    this.replaceInputValue();
    this.propertyTypes = this.residentialTypes;
    // this.initMap();
    this.getBanners();
    this.$store.commit('v2/search/CLEAR_FILTER');
  },
  methods: {
    async initMap() {
      try {
        this.loadingMap = true;
        const { data } = await this.$http.get(
          'https://apiv2.rentfix.com/api/v2/search?listing_type=R&page=1&sort_by=1&paginate=2000',
          // 'https://api.propfix.sg/api/v2/search?listing_type=R&page=1&sort_by=1&paginate=30',
        );
        this.listings = data.data.data;
        this.meta = data.data.meta;
        // eslint-disable-next-line no-empty
      } catch (_) {
      } finally {
        this.loadingMap = false;
      }
    },
    async getSuggestions() {
      try {
        this.getSuggestionLoading = true;
        let response = await this.$store.dispatch('v2/search/getSuggestions', {
          query: this.currentKeyword,
          listingType: this.listingType,
          searchType: this.searchType,
        });
        if (response.type === 'success') {
          this.suggestions = response.data;
        }
      } finally {
        this.getSuggestionLoading = false;
      }
    },
    selectedSuggestion(data) {
      this.currentKeyword = data.name;
      this.$store.commit('v2/search/SET_SEARCH_TERM', data.name);
      this.$store.commit('v2/search/SET_SEARCH_TERM_ID', data.id);
      this.$store.commit('v2/search/SET_SEARCH_TERM_CATEGORY', data.category);
    },
    replaceInputValue() {
      let self = this;
      if (self.$refs.searchTypehead !== undefined) {
        self.$refs.searchTypehead.inputValue = this.searchTerm;
      } else {
        setTimeout(function() {
          self.replaceInputValue();
        }, 500);
      }
    },
    changeType(newType) {
      this.currentKeyword = null;
      this.$store.commit('v2/search/SET_SEARCH_TERM', null);
      this.$store.commit('v2/search/SET_SEARCH_TERM_ID', null);
      this.$store.commit('v2/search/SET_SEARCH_TERM_CATEGORY', null);
      this.replaceInputValue();
      this.$store.commit('v2/search/CLEAR_FILTER');
      this.$store.commit('v2/search/SET_LISTING_TYPE', newType);
    },
    goToSearch() {
      this.$store.dispatch('v2/search/goToSearchPage', {
        router: this.$router,
        params: this.$route.params,
      });
    },
    changePropertyTypeGroup() {
      let newPropertyTypeGroup = 'RESIDENTIAL';
      if (this.propertyTypeGroup === 'RESIDENTIAL') {
        newPropertyTypeGroup = 'COMMERCIAL';
      } else if (this.propertyTypeGroup === 'COMMERCIAL') {
        newPropertyTypeGroup = 'RESIDENTIAL';
      }
      this.propertyTypeGroup = newPropertyTypeGroup;
      this.loadPropertyTypeList();
    },
    loadPropertyTypeList() {
      let self = this;
      if (this.propertyTypeGroup === 'RESIDENTIAL') {
        this.propertyTypes = this.residentialTypes.filter(function(result) {
          return self.listingType === 'S' ? !result.onlyRent : true;
        });
      } else if (this.propertyTypeGroup === 'COMMERCIAL') {
        this.propertyTypes = this.commercialTypes.filter(function(result) {
          return self.listingType === 'S' ? !result.onlyRent : true;
        });
      }
    },
    async getBanners() {
      await this.$store.dispatch('v2/banner/getBanners');
    },
  },
};
</script>

<style lang="scss" scoped>
.v-enter-active {
  display: none;
  transition: opacity 0.5s ease;
}

.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
